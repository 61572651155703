import { FooterItem, FooterLink, FooterWrap } from './styled';

import TelegramIcon from '@mui/icons-material/Telegram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';

const Footer = () => {
  return (
    <FooterWrap>
      <FooterItem>
        <FooterLink type="tw" href="https://www.facebook.com/ericferrandez.photo" target="_blank">
          <FacebookIcon />
        </FooterLink>
      </FooterItem>
      <FooterItem>
        <FooterLink type="ig" href="https://instagram.com/ericferrandez.photo" target="_blank">
          <InstagramIcon />
        </FooterLink>
      </FooterItem>
      <FooterItem>
        <FooterLink type="in" href="https://www.linkedin.com/in/ericferrandez/" target="_blank">
          <LinkedInIcon />
        </FooterLink>
      </FooterItem>
      <FooterItem>
        <FooterLink type="tg" href="https://t.me/EricBayaz" target="_blank">
          <TelegramIcon />
        </FooterLink>
      </FooterItem>
    </FooterWrap>
  )
}

export default Footer;
