import styled from 'styled-components';

import { ViewModeQueries } from '@theme/viewMode';

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
`;

const HeaderWrap = styled.div`
  flex: 0 0 auto;
  padding: 10px;

  ${ViewModeQueries.MD} {
    flex: initial;
    height: 80px;
    padding: 0 0;
  }
`;

const ContentWrap = styled.div`
  flex: 1 1 auto;
  position: relative;
  box-sizing: border-box;
  margin: 5px 0;
  width: 100%;
  max-width: 800px;
  height: calc(100vh - 50px);
  overflow-y: auto;
  overflow-x: hidden;

  ${ViewModeQueries.MD} {
    flex: initial;
    margin: 20px auto;
    height: calc(100vh - 180px);
  }
`;

const FooterWrap = styled.div`
  flex: 0 0 auto;
  padding: 10px;

  ${ViewModeQueries.MD} {
    flex: initial;
    height: 60px;
    padding: 0 0;
  }
`;

const GridLayout = styled.div`
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 100%;
  height: 100vh;
  overflow: hidden;
  
  ${ViewModeQueries.MD} {
    grid-template-columns: 20% 80%;
  }
`;

const GridHeader = styled.div`
  display: grid;
  background-color: #3C3D37;
  border-right: 1px solid #3c3b37;
  overflow: hidden;
`;

const GridContent = styled.div`
  background-color: #1E201E;
  height: 100%;
  overflow: auto;
`;

const GridFooter = styled.div`
  align-self: end;
  padding-bottom: 20px;
`;

export { LayoutContainer, HeaderWrap, ContentWrap, FooterWrap, GridLayout, GridHeader, GridContent, GridFooter };
