enum ViewModeWidth {
  XXL = 1367,
  XL = 1172,
  LG = 992,
  MD = 768,
  SM = 576,
}
const ViewModeQueries = {
  XXL: `@media screen and (min-width: ${ViewModeWidth.XXL}px)`,
  XL: `@media screen and (min-width: ${ViewModeWidth.XL}px)`,
  LG: `@media screen and (min-width: ${ViewModeWidth.LG}px)`,
  MD: `@media screen and (min-width: ${ViewModeWidth.MD}px)`,
  SM: `@media screen and (min-width: ${ViewModeWidth.SM}px)`,
};

export { ViewModeWidth, ViewModeQueries }
