import * as React from 'react';

import { GridHeaderWrap, GridHeaderContainer, GridMenuWrap, GridLogoWrap, GridMenuItem } from './styled';

import HouseIcon from '@mui/icons-material/House';
import DevicesIcon from '@mui/icons-material/Devices';
import CameraIcon from '@mui/icons-material/Camera';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

import HeaderLogo from '@assets/logoWeb.webp';

const SimpleHeader = () => {
  return (
    <GridHeaderWrap>
      <GridHeaderContainer>
        <GridLogoWrap>
          <img src={HeaderLogo} alt="" />
        </GridLogoWrap>
        <GridMenuWrap>
          <GridMenuItem to="/">
            <HouseIcon />
            <p>Home</p>
          </GridMenuItem>
          <GridMenuItem to="/portfolio">
            <DevicesIcon />
            <p>Portfolio</p>
          </GridMenuItem>
          <GridMenuItem to="/fotografia">
            <CameraIcon />
            <p>Fotografía</p>
          </GridMenuItem>
          <GridMenuItem to="/contacto">
            <AlternateEmailIcon />
            <p>Contacto</p>
          </GridMenuItem>
        </GridMenuWrap>
      </GridHeaderContainer>
    </GridHeaderWrap>
  )
}

export default SimpleHeader;
