import styled from 'styled-components';

const FeedContainer = styled.div`
  margin-top: 30px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  row-gap: 15px;
  column-gap: 15px;
  justify-content: center;
`;

const ImageText = styled.div`
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, .5);
  color: #0d2538;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  border-radius: 6px;
  font-size: .8rem;
  text-shadow: 0 0 2px black;
  padding: 0 5px;
`;

const ImageLink = styled.a`
  text-align: center;
  position: relative;
  border-radius: 6px;
  width: auto;
  max-width: 100%;
  border: 2px solid #fff;
  overflow: hidden;
  
  img {
    width: 100%;
    height: auto;
  }

  ${ImageText} {
    transition: all 0.3s ease-in-out;
    display: none;
  }
  
  :hover {
    ${ImageText} {
      display: flex;
    }
  }
`;

export { FeedContainer, ImageText, ImageLink };
