import React, { useEffect, useMemo, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import Person2Icon from '@mui/icons-material/Person2';
import EmailIcon from '@mui/icons-material/Email';
import TitleIcon from '@mui/icons-material/Title';
import MessageIcon from '@mui/icons-material/Message';
import DoneAllIcon from '@mui/icons-material/DoneAll';

import { Title, P, A, FullyCenterWrap, Box } from '@pages/common/styled';
import { Form, Row, IconWrap, Input, Textarea, Button, SuccessWrap } from './styled';
import customAdd from '@components/Firebase/customAdd';

const Contact = () => {
  customAdd('Loaded contact page');

  useEffect(() => {
    document.title = 'Eric Ferrández » Contacto';
  }, []);

  const [formName, setFormName] = useState<string>('');
  const [formMail, setFormMail] = useState<string>('');
  const [formTitle, setFormTitle] = useState<string>('');
  const [formMessage, setFormMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [error, setError] = useState<any>(undefined);


  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    const url = 'https://api.ericferrandez.es/mail.php';
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=UTF-8' },
      body: JSON.stringify({
        name: formName,
        email: formMail,
        title: formTitle,
        message: formMessage,
      }),
    };

    try {
      await fetch(url, options)
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          const { isSent } = result;

          if (isSent === true) {
            return setIsSuccess(true);
          } else {
            setError('Ha ocurrido un error enviando el correo. Intentalo de nuevo en unos instantes, por favor.');

            return setIsError(true);
          }
        })
        .catch((error) => {
          setError(error?.message);
          return setIsError(true);
        });
      setIsLoading(false);
      return Promise.resolve();
    } catch (e) {
      setError('Ha ocurrido un error enviando el correo. Intentalo de nuevo en unos instantes, por favor.');
      setIsError(true);
      setIsLoading(false);
      return Promise.reject(e);
    }
  };

  const validateForm = useMemo(() => {
    if (!formName || formName?.trim()?.length < 3) {
      return false;
    }

    const validateMail = formMail ? String(formMail).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) : false;
    if (!validateMail) {
      return false;
    }

    if (!formTitle || formTitle?.trim()?.length < 5) {
      return false;
    }

    if (!formMessage || formMessage?.trim()?.length < 20) {
      return false;
    }

    return !(!formName || !formMail || !formTitle || !formMessage);
  }, [formName, formMail, formTitle, formMessage]);

  useEffect(() => {
    console.log('validateForm', validateForm);
  }, [validateForm]);

  return (
    <FullyCenterWrap>
      <Box>
        <Title>Contacto</Title>
        <P>
          Si quieres ponerte en contacto conmigo, para lo que sea, puedes hacer
          clic <A href="mailto:eric@ericferrandez.es?subject=Contacto desde ericferrandez.es">aquí</A> para mandármelo desde tu gestor de correo favorito.
        </P>
        <P>O, si lo prefieres, tienes un formulario de contacto disponible justo debajo.</P>

        <Form method="post" action="" onSubmit={handleSubmit}>
          <Row>
            <IconWrap>
              <Person2Icon />
            </IconWrap>
            <Input
              type="text"
              placeholder="Tu nombre"
              name="name" onChange={(event: any) => setFormName(event.target.value)}
              autoComplete="off"
              required
              readOnly={isSuccess}
            />
          </Row>
          <Row>
            <IconWrap>
              <EmailIcon />
            </IconWrap>
            <Input
              type="text"
              placeholder="Tu correo electrónico"
              name="email"
              onChange={(event: any) => setFormMail(event.target.value)}
              autoComplete="off"
              required
              readOnly={isSuccess}
            />
          </Row>
          <Row>
            <IconWrap>
              <TitleIcon />
            </IconWrap>
            <Input
              type="text"
              placeholder="Asunto"
              name="title"
              onChange={(event: any) => setFormTitle(event.target.value)}
              autoComplete="off"
              required
              readOnly={isSuccess}
            />
          </Row>
          <Row>
            <IconWrap>
              <MessageIcon />
            </IconWrap>
            <Textarea
              placeholder="Escribe tu mensaje"
              name="message"
              rows={5}
              onChange={(event: any) => setFormMessage(event.target.value)}
              autoComplete="off"
              required
              readOnly={isSuccess}
            />
          </Row>
          {isError && <P>{error}</P>}
          {isSuccess ? (
            <SuccessWrap>
              <DoneAllIcon />
              <P>¡Mensaje enviado! Muchas gracias, será respondido a la mayor brevedad posible.</P>
            </SuccessWrap>
          ) : (
            <Button type="submit" disabled={isSuccess || isLoading || !validateForm}>
              {isLoading ? <CircularProgress /> : 'Enviar'}
            </Button>
          )}
        </Form>
      </Box>
    </FullyCenterWrap>
  );
};

export default Contact;
