import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import { Title, P, FullyCenterWrap, Box } from '@pages/common/styled';

import { FeedContainer, ImageText, ImageLink } from './styled';

import customAdd from '@components/Firebase/customAdd';

const PhotographyIg = () => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  customAdd('Loaded photography page');

  const retrieveData = async () => {
    setLoading(true);
    const url = 'https://api.ericferrandez.es/ig.php';

    const response = await fetch(url);

    if (response.ok) {
      const json = await response.json();
      const parse = JSON.parse(json);
      console.log('parse', parse);
      setData(parse?.data);
      setLoading(false);
    } else {
      console.error('error', response.status);
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = 'Eric Ferrández » Fotografía';
    retrieveData();
  }, []);

  return (
    <FullyCenterWrap>
      <Box>
        <Title>Fotografía</Title>
        <P>Coloca el cursor sobre cualquier imagen para ver el título, y clica para verla a tamaño completo en Instagram.</P>
        <P>¡Todo like y follow es bienvenido!</P>
        <FeedContainer>
          {loading && (
            <>
              <div />
              <div style={{width: '100%', textAlign: 'center'}}>
                <CircularProgress size="3rem" color="inherit"/>
              </div>
              <div />
            </>
          )}
          {data && data?.map((item: { caption: string; media_url: string; permalink: string; id: string; }) => (
            <ImageLink href={item?.permalink} target='_blank' key={item.id}>
              <img src={item?.media_url} alt="" />
              <ImageText>{item?.caption?.length > 130 ? `${item?.caption?.slice(0, 130)}...` : item?.caption}</ImageText>
            </ImageLink>
          ))}
        </FeedContainer>
      </Box>
    </FullyCenterWrap>
  );
};

export default PhotographyIg;
