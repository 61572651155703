import React, { useEffect } from 'react';

import { DevImg, FullyCenterWrap, Title, Multicolor, Subtitle, InfoDiv, Info } from './styled';
import { BlurBox } from '@pages/common/styled';

import DevPic from '@assets/dev.jpeg';

import PlaceIcon from '@mui/icons-material/Place';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import MailIcon from '@mui/icons-material/Mail';
import CameraIcon from '@mui/icons-material/Camera';
import customAdd from '@components/Firebase/customAdd';

const Home = () => {
  customAdd('Loaded home page');

  useEffect(() => {
    document.title = 'Eric Ferrández » Home';
  }, []);

  return (
    <FullyCenterWrap>
      <DevImg src={DevPic} alt="" />
      <BlurBox>
        <Title>Hola, soy <Multicolor>Eric</Multicolor></Title>
        <Subtitle>Este es mi website personal y portfolio</Subtitle>
        <InfoDiv>
          <PlaceIcon />
          <Info>residente en León, España</Info>
        </InfoDiv>
        <InfoDiv>
          <LaptopMacIcon />
          <Info>Front End developer</Info>
        </InfoDiv>
        <InfoDiv>
          <CameraIcon />
          <Info>Fotógrafo con Sony α</Info>
        </InfoDiv>
        <InfoDiv>
          <MailIcon />
          <Info>eric@ericferrandez.es</Info>
        </InfoDiv>
      </BlurBox>
    </FullyCenterWrap>
  );
};

export default Home;
