import React, { useEffect } from 'react';

import './terminal.css';

import customAdd from '@components/Firebase/customAdd';
import { Box, FullyCenterWrap } from '@pages/common/styled';

const Portfolio = () => {
  customAdd('Loaded portfolio page');

  useEffect(() => {
    document.title = 'Eric Ferrández » Portfolio';
  }, []);

  return (
    <FullyCenterWrap>
      <Box id="terminal">
        <section id="terminal__bar">
          <div id="bar__buttons">
            <button className="bar__button" id="bar__button--exit">&#10005;</button>
            <button className="bar__button">&#9472;</button>
            <button className="bar__button">&#9723;</button>
          </div>
          <p id="bar__user">eric@ubuntu: ~</p>
        </section>
        <section id="terminal__body">
          <div id="terminal__prompt">
            <span id="terminal__prompt--user">eric@ubuntu:</span>
            <span id="terminal__prompt--location">~</span>
            <span id="terminal__prompt--bling">$ cd skills && ls && cd ..</span>
          </div>
          <div id="terminal__prompt" className="margin-top">
            <p id="terminal__prompt--title">Lenguajes</p>
          </div>
          <div id="terminal__prompt--two">
            <div id="terminal__prompt--two-bling">TypeScript</div>
            <div id="terminal__prompt--two-bling">JavaScript</div>
            <div id="terminal__prompt--two-bling">PHP</div>
            <div id="terminal__prompt--two-bling">HTML/CSS</div>
          </div>
          <div id="terminal__prompt" className="margin-top">
            <p id="terminal__prompt--title">Frameworks</p>
          </div>
          <div id="terminal__prompt--two">
            <div id="terminal__prompt--two-bling">React</div>
            <div id="terminal__prompt--two-bling">Redux</div>
            <div id="terminal__prompt--two-bling">Material UI / MUI</div>
            <div id="terminal__prompt--two-bling">React-admin</div>
            <div id="terminal__prompt--two-bling">React Native (WIP)</div>
            <div id="terminal__prompt--two-bling">Bootstrap</div>
            <div id="terminal__prompt--two-bling">jQuery</div>
          </div>
          <div id="terminal__prompt" className="margin-top">
            <p id="terminal__prompt--title">Bases de datos</p>
          </div>
          <div id="terminal__prompt--two">
            <div id="terminal__prompt--two-bling">MySQL</div>
            <div id="terminal__prompt--two-bling">BigQuery</div>
          </div>
          <div id="terminal__prompt" className="margin-top">
            <p id="terminal__prompt--title">Herramientas</p>
          </div>
          <div id="terminal__prompt--two">
            <div id="terminal__prompt--two-bling">Git</div>
            <div id="terminal__prompt--two-bling">npm / yarn</div>
            <div id="terminal__prompt--two-bling">Postman</div>
            <div id="terminal__prompt--two-bling">Docker</div>
            <div id="terminal__prompt--two-bling">Jenkins</div>
            <div id="terminal__prompt--two-bling">Jira</div>
            <div id="terminal__prompt--two-bling">Confluence</div>
            <div id="terminal__prompt--two-bling">Bitbucket</div>
          </div>
          <div id="terminal__prompt">
            <span id="terminal__prompt--user">eric@ubuntu:</span>
            <span id="terminal__prompt--location">~</span>
            <span id="terminal__prompt--bling">$</span>
            <span id="terminal__prompt--cursor"></span>
          </div>
        </section>
      </Box>
    </FullyCenterWrap>
  );
};

export default Portfolio;
