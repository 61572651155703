import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ViewModeQueries } from '@theme/viewMode';

const HeaderWrap = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 800px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 20px 20px;
  
  ${ViewModeQueries.MD} {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`;

const LogoWrap = styled.div`
  font-family: 'Indie Flower', sans-serif;
  font-size: 30px;
  line-height: 34px;
  color: #cd60db;
  text-shadow: 1px 1px #2B2A33;

  ${ViewModeQueries.MD} {
    font-size: 40px;
    line-height: 44px;
  }
`;

const MenuWrap = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 25px 12px;
  justify-content: center;

  ${ViewModeQueries.MD} {
    flex-flow: row nowrap;
    gap: 0 12px;
  }
`;

const LinkWrap = styled.div``;

const MenuLink = styled.a`
  position: relative;
  overflow: hidden;
  padding: 5px 10px;
  color: #ffffff;
  text-decoration: none;
  z-index: 1;

  :hover {
    color: #fff;
  }

  :before,
  :after {
    position: absolute;
    width: 0px;
    height: 100%;
    top: 0;
    bottom: 0;
    background: #cd60db;
    border-radius: 5px;
    transition: all .5s;
    margin: auto;
    content: '.';
    color: transparent;
    z-index: -1;
    opacity: 0.75;
  }

  :before {
    left: 0;
  }
  :after {
    right: 0;
  }

  :hover:after,
  :hover:before {
    width: 100%;
  }
`;

const GridHeaderWrap = styled.div`
  display: flex;
  padding: 30px 20px;
`;

const GridHeaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px 0;
`;

const GridLogoWrap = styled.div`
  text-align: center;

  img {
    width: 100%;
    max-width: 200px;
    height: auto;
  }
`;

const GridMenuWrap = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 25px 0;
`;

const GridMenuItem = styled(Link)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0 8px;
  text-decoration: none;
  color: #FFF;
  padding: 5px 8px;
  border-radius: 6px;
  transition: all .4s ease-in-out;
  
  p {
    font-size: 1.2rem;
  }
  
  &:hover {
    cursor: pointer;
    background-color: #697565;
    
    p {
      color: pink;
    }
    
    svg {
      color: pink;
    }
  }
`;

export {
  HeaderWrap,
  LogoWrap,
  MenuWrap,
  LinkWrap,
  MenuLink,
  GridHeaderWrap,
  GridHeaderContainer,
  GridLogoWrap,
  GridMenuWrap,
  GridMenuItem,
};
