import React, { useEffect } from 'react';


import PlaceIcon from '@mui/icons-material/Place';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import MailIcon from '@mui/icons-material/Mail';
import CameraIcon from '@mui/icons-material/Camera';

import customAdd from '@components/Firebase/customAdd';

import { Box } from '@pages/common/styled';
import { Info, Subtitle, InfoDiv, LogoWrap } from '@pages/Home/styled';
import { ButtonsWrap, FullButton } from '@pages/Profile/styled';
import HeaderLogo from '@assets/logoWeb.webp';

const Profile = () => {
  customAdd('Loaded profile page');

  useEffect(() => {
    document.title = 'Eric Ferrández » Home';
  }, []);

  return (
    <Box>
      <LogoWrap>
        <img src={HeaderLogo} alt=""/>
      </LogoWrap>
      <InfoDiv>
        <PlaceIcon />
        <Info>residente en León, España</Info>
      </InfoDiv>
      <InfoDiv>
        <LaptopMacIcon />
        <Info>Front End developer</Info>
      </InfoDiv>
      <InfoDiv>
        <CameraIcon />
        <Info>Fotógrafo con Sony α</Info>
      </InfoDiv>
      <InfoDiv>
        <MailIcon />
        <Info>eric@ericferrandez.es</Info>
      </InfoDiv>
      <Subtitle style={{ marginTop: '20px' }}>Mis enlaces</Subtitle>
      <ButtonsWrap>
        <FullButton href="https://instagram.com/ericferrandez.photo" target="_blank">Instagram</FullButton>
        <FullButton href="https://www.facebook.com/ericferrandez.photo" target="_blank">Facebook</FullButton>
        <FullButton href="https://www.linkedin.com/in/ericferrandez/" target="_blank">LinkedIn</FullButton>
        <FullButton href="https://t.me/EricBayaz" target="_blank">Telegram</FullButton>
        <FullButton href="mailto:eric@ericferrandez.es?subject=Contacto desde ericferrandez.es" target="_blank">¡Mándame un correo!</FullButton>
      </ButtonsWrap>
    </Box>
  );
};

export default Profile;
