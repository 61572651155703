import React from 'react';

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

import '@theme/reset.css';
import '@theme/app.css';

import SimpleLayout from '@layout/SimpleLayout';

function App() {
  const firebaseConfig = {
    apiKey: "AIzaSyCu-TwyK9I1wJYJYnqqsyobWP2b1o2m-f8",
    authDomain: "ericferrandezwebsite.firebaseapp.com",
    projectId: "ericferrandezwebsite",
    storageBucket: "ericferrandezwebsite.appspot.com",
    messagingSenderId: "692048088977",
    appId: "1:692048088977:web:edee5e06f08e43a3b9d430",
    measurementId: "G-SPJ08V2LQ1"
  };

  const app = initializeApp(firebaseConfig);
  getAnalytics(app);

  return (
    <>
      <SimpleLayout />
    </>
  );
}

export default App;
