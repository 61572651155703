import styled, { css } from 'styled-components';

const FooterWrap = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 0 20px;
`;

const FooterItem = styled.div``;

const FooterLink = styled.a<{ type: string }>`
  text-decoration: none;
  padding: 15px 15px 0 15px;
  transition: .5s;
  border-radius: 16px;
  
  &:hover,
  &:focus {
    background-color: #2b2a33;
    color: rgb(251, 251, 254);
  }

  ${(props) =>
    props.type === 'ig' &&
    css`
      color: #f44747;
    `};

  ${(props) =>
    props.type === 'tg' &&
    css`
      color: #0088cc;
    `};

  ${(props) =>
    props.type === 'in' &&
    css`
      color: #00a0dc;
    `};

  ${(props) =>
    props.type === 'tw' &&
    css`
      color: #1da1f2;
    `};
`;

export { FooterWrap, FooterItem, FooterLink };
