import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import SimpleHeader from '@components/SimpleHeader';
import { GridLayout, GridHeader, GridContent, GridFooter } from '@layout/styled';
import Home from '@pages/Home';
import Portfolio from '@pages/Portfolio';
import Contact from '@pages/Contact';
import NoPage from '@pages/NoPage';
import PhotographyIg from '@pages/PhotographyIg';
import Profile from '@pages/Profile';
import SimpleFooter from '@components/SimpleFooter';
import { ViewModeWidth } from '@theme/viewMode';

const SimpleLayout = () => {
  const isMinMD = useMediaQuery({
    minWidth: ViewModeWidth.MD,
  });

  return (
    <BrowserRouter>
      <GridLayout>
        {isMinMD && (
          <GridHeader>
            <SimpleHeader />
            <GridFooter>
              <SimpleFooter />
            </GridFooter>
          </GridHeader>
        )}
        <GridContent>
          <Routes>
            <Route index element={isMinMD ? <Home /> : <Profile />} />
            <Route path="portfolio" element={<Portfolio />} />
            <Route path="fotografia" element={<PhotographyIg />} />
            <Route path="contacto" element={<Contact />} />
            <Route path="*" element={<NoPage />} />
          </Routes>
        </GridContent>
      </GridLayout>
    </BrowserRouter>
  )
};

export default SimpleLayout;
