import styled from 'styled-components';

const ButtonsWrap = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 15px 0;
  width: 100%;
`;
const FullButton = styled.a`
  border: 2px solid rgba(255, 255, 255, .8);
  background-color: rgba(255, 255, 255, .3);
  color: #090909;
  text-align: center;
  padding: 12px 15px;
  border-radius: 4px;
  text-decoration: none;
  
  :hover {
    background-color: rgba(255, 255, 255, .8);
  }
`;

export { ButtonsWrap, FullButton };
