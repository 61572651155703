import styled from 'styled-components';

import { ViewModeQueries } from '@theme/viewMode';

const DevImg = styled.img`
  background: -webkit-linear-gradient(135deg, rgb(154, 23, 163), rgb(23, 86, 163));
  object-fit: cover;
  border-radius: 50%;
  padding: 0.25rem;
  min-height: 120px;
  max-height: 240px;
`;

const FullyCenterWrap = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 25px 0;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 100%;

  ${ViewModeQueries.MD} {
    flex-flow: row nowrap;
    gap: 0 25px;
  }
`;

const Title = styled.p`
  font-size: 34px;
  font-weight: bold;
  line-height: 34px;
  margin-bottom: 10px;
`;
const Multicolor = styled.span`
  background: -webkit-linear-gradient(135deg, rgb(154, 23, 163), rgb(23, 86, 163));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
const Subtitle = styled.p`
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 20px;
`;

const InfoDiv = styled.div`
  display: grid;
  grid-template-columns: 35px auto;
  align-items: center;
  margin-bottom: 12px;

  ${ViewModeQueries.MD} {
    margin: 12px 20px 0;
  }
`;
const Info = styled.p`
  font-size: 16px;
  line-height: 16px;
`;

const LogoWrap = styled.div`
  text-align: center;
  margin-bottom: 10px;

  img {
    width: 100%;
    max-width: 200px;
    height: auto;
  }
`;

export { DevImg, FullyCenterWrap, Title, Multicolor, Subtitle, InfoDiv, Info, LogoWrap };
