import React, { useEffect } from 'react';
import { BlurBox, Title, P } from '@pages/common/styled';
import customAdd from '@components/Firebase/customAdd';

const NoPage = () => {
  customAdd('Loaded error page');

  useEffect(() => {
    document.title = 'Eric Ferrández » Oops! Error!';
  }, []);

  return (
    <BlurBox>
      <Title>¡Error!</Title>
      <P>Oops, has caido en una url que no existe :(</P>
    </BlurBox>
  );
};

export default NoPage;
