import styled from 'styled-components';
import { ViewModeQueries } from '@theme/viewMode';

const Title = styled.p`
  font-size: 38px;
  line-height: 42px;
  text-align: center;
  margin-bottom: 25px;
`;

const P = styled.p`
  margin: 15px 0;
  padding: 0 35px;
  text-align: justify;
`;

const A = styled.a`
  color: rgb(205, 96, 219);
  text-decoration: none;
  border-bottom: 1px solid #2B2A33;
  border-top: 1px solid transparent;
  transition: 0.4s;
  
  :hover {
    border-top-color: #fbfbfe;
    border-bottom-color: #fbfbfe;
  }
`;

const I = styled.span`
  font-style: italic;
`;

const BlurBox = styled.div`
  box-sizing: border-box;
  border-radius: 1rem;
  background: rgba(0, 0, 0, .2);
  box-shadow: rgba(0, 0, 0, .2) 0 0 10px 0;
  backdrop-filter: blur(8px);
  padding: 1rem 2rem;
`;

const Box = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 0.6rem 1.4rem;
  
  ${ViewModeQueries.MD} {
    padding: 2rem 3rem;
    max-height: 100%;
  }
`;

const FullyCenterWrap = styled.div`
  box-sizing: border-box;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

export { Title, P, I, A, BlurBox, Box, FullyCenterWrap };
